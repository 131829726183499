import { IconButton, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { ConfirmDialog } from "./ConfirmDialog";
import { useRef } from "react";
import { MyListApi } from "../utils/restApi";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../slices/notificationSlice";
// import { setNotification } from "../slices/notificationSlice";

export const FriendsList = ({ friends }) => {

    const ref = useRef();
    const user = useSelector(state => state.login.user);
    const dispatch = useDispatch();

    const handleConfirmAcceptFriend = (friendId) => {
        ref.current.openConfirm(() => {
            MyListApi.acceptFriendRequest(friendId)
                .then(data => {
                })
                .catch(error => {
                    dispatch(addNotification({
                        message: error.message,
                        type: 'error',
                        // open: true,
                    }));
                });
        });
    }

    return (
        <div>
            <ConfirmDialog title="Accept Friend Request" message="Accept friend request?" ref={ref} />
            <TableContainer component="div">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            {/* <TableCell>Name</TableCell> */}
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {friends.map(row => {
                            // const isMyPending = row?.user_link.user_id === user.id && row?.user_link?.friend_request === 'PENDING';
                            // const isPending = !isMyPending && row?.user_link?.friend_request === 'PENDING';
                            // const isFriend = row?.user_link?.friend;
                            // const name = row?.name || '';
                            // const isLink = !isMyPending && !isPending && row?.user_link?.linked_user !== user.id;

                            const requested = row.friend_user.id == user.id;

                            let name = requested ? row.user.name : row.friend_user.name;
                            let status = requested ? 'awaiting approval' : row.status;

                            return (
                            <TableRow key={row.id}>
                                <TableCell>
                                    {name}
                                </TableCell>
                                <TableCell>
                                    {status}
                                </TableCell>
                                <TableCell>
                                    {requested ?
                                        <Tooltip title="Accept Friend Request" arrow>
                                            <IconButton color="primary" onClick={() => handleConfirmAcceptFriend(row.id)}><PersonAddIcon /></IconButton>
                                        </Tooltip>
                                    : undefined}
                                </TableCell>
                                {/* <TableCell sx={{
                                    width: "200px",
                                }}>
                                    {isLink ? <Link href={`/user/${name}/items`}>{`@${name}`}</Link> : `@${row.name}`}
                                </TableCell>
                                <TableCell>{row.display_name}</TableCell>
                                <TableCell sx={{
                                    width: "175px",
                                }}>
                                    {isPending ? <Tooltip title="Accept Friend Request" arrow><IconButton color="primary" onClick={() => handleConfirmAcceptFriend(row.user_link.id)}><PersonAddIcon /></IconButton></Tooltip> : null}
                                    {isFriend ? <Tooltip title="Remove Friend" arrow><IconButton><GroupRemoveIcon /></IconButton></Tooltip> : null}
                                    <Tooltip title="Block User" arrow><IconButton><BlockIcon /></IconButton></Tooltip>
                                    {isMyPending ?
                                        <Tooltip title="Pending Friend Request" arrow><IconButton><PersonPinIcon /></IconButton></Tooltip>
                                    : null}
                                </TableCell> */}
                            </TableRow>
                        )})}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}