import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MyListApi } from "../utils/restApi";

export const fetchActivities = createAsyncThunk('activities/fetchActivities', async ({friends, page}) => {
    const data = await MyListApi.getActivities(friends, page);
    return data.data;
});

const activitiesSlice = createSlice({
    name: 'activities',
    initialState: {
        activities: [],
        count: 0,
        page: 0,
        status: 'idle',
        error: null,
    },
    reducers: {
        resetStatus: (state) => {
            state.status = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchActivities.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchActivities.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.activities = action.payload.items;
                state.count = action.payload.count;
                state.page = action.payload.page;
            })
            .addCase(fetchActivities.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { resetStatus } = activitiesSlice.actions;
export default activitiesSlice.reducer;