import { Badge, Box, Button, Chip, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { MyListApi } from "../utils/restApi";
import { notification, shortenNumber } from "../utils/utils";
import { useSelector } from "react-redux";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import UndoIcon from '@mui/icons-material/Undo';
import { parseISO } from "date-fns";

const getPropertyValue = (itemData, property) => {
    const value = itemData?.[`${property}`];
    if (value === undefined || value == null) {
        return '';
    }
    if (typeof value === 'object') {
        if (value.constructor.name === 'Date') {
            return value.toDateString();
        }
        return '';
    }
    return value;
}

const getValueByType = (value, type) => {
    switch (type) {
        case 'date': return parseISO(value).toDateString();
        default: return value;
    }
}

export const ItemUpdateDialog = forwardRef(({ itemData, dataInfo }, ref) => {

    const user = useSelector(state => state.login.user);
    const [open, setOpen] = useState(false);
    const [itemUpdate, setItemUpdate] = useState(null);

    useImperativeHandle(ref, () => {
        return {
            openItemUpdateDialog: (itemUpdate) => {
                setItemUpdate(itemUpdate);
                setOpen(true);
            }
        }
    });

    const handleDialogClose = () => {
        setOpen(false);
    }

    // console.log('dataInfo', dataInfo);

    const property = itemUpdate?.property;
    const type = dataInfo?.find(di => di.name === property)?.type || '';
    // console.log('property', property);
    // console.log('type', type);
    const curValue = getPropertyValue(itemData, property);
    const newValue = getValueByType(itemUpdate?.value, type);

    const handleValid = () => {
        setOpen(false);
        MyListApi.itemUpdateVote(itemUpdate?.id, true)
            .then(data => {
                console.log('data', data);
                notification("Item update successfully indicated as valid.", "success");
            })
            .catch(error => {
                notification(error.message, "error");
            });
    }

    const handleInvalid = () => {
        setOpen(false);
        MyListApi.itemUpdateVote(itemUpdate?.id, false)
            .then(data => {
                notification("Item update successfully indicated as invalid.", "success");
            })
            .catch(error => {
                notification(error.message, "error");
            });
    }

    const handleApply = () => {
        setOpen(false);
        MyListApi.itemUpdateApply(itemUpdate?.id, true)
            .then(data => {
                notification("All good", "success");
            })
            .catch(error => {
                notification(error.message, "error");
            });
    }

    const handleReject = () => {
        setOpen(false);
        MyListApi.itemUpdateApply(itemUpdate?.id, false)
            .then(data => {
                notification("All good", "success");
            })
            .catch(error => {
                notification(error.message, "error");
            });
    }

    const applyCount = shortenNumber(itemUpdate?.apply_count || 0);
    const rejectCount = shortenNumber(itemUpdate?.reject_count || 0);

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>Item Update</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h6">{property}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box>
                            <UndoIcon sx={{ transform: 'rotate(-90deg)'}} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignContent: 'center', width: '100%' }}>
                            <Box sx={{ backgroundColor: '#CEE7E6', height: '50px', borderRadius: '7px', padding: '7px', alignContent: 'center' }}>
                                <Typography>{curValue}</Typography>
                            </Box>
                            <Box sx={{ backgroundColor: '#CEE7E6', height: '50px', borderRadius: '7px', padding: '7px', alignContent: 'center' }}>
                                <Typography>{newValue}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: '300px', padding: '20px 0 20px' }}>
                        <Typography variant="caption">Click below to cast your vote to indicate whether the requested value update is valid or invalid.</Typography>
                    </Box>
                    <Box sx={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
                        <Badge badgeContent={rejectCount} color="error" max={999}>
                            <Chip color="error" label="Invalid" icon={<RemoveCircleIcon />} onClick={handleInvalid} sx={{ flexGrow: 1, width: '120px', backgroundColor: '#EF6F6C', color: 'white', '&:hover': { backgroundColor: 'darkred' } }} />
                        </Badge>
                        <Badge badgeContent={applyCount} color="success" max={999}>
                            <Chip label="Valid" icon={<CheckCircleIcon color="white" />}  onClick={handleValid} sx={{ flexGrow: 1, width: '120px', backgroundColor: '#648767', color: 'white', '&:hover': { backgroundColor: 'darkgreen' } }} />
                        </Badge>
                    </Box>
                </Box>
            </DialogContent>
            <Box sx={{ padding: '20px 20px 20px' }}>
                {user?.is_admin ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', textAlign: 'center', padding: '10px 0 10px 0' }}>
                        <Typography variant="caption" sx={{ paddingBottom: '10px' }}>Admin or Moderator: Apply or Reject this update.</Typography>
                        <Button onClick={handleApply}>Apply</Button>
                        <Button onClick={handleReject}>Reject</Button>
                    </Box>
                : undefined}
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '10px 0 0 0' }}>
                    <Button onClick={handleDialogClose} variant="contained">Close</Button>
                </Box>
            </Box>
        </Dialog>
    )
});