import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MyListApi } from "../utils/restApi";

export const fetchTags = createAsyncThunk('tags/fetchTags', async () => {
    const data = await MyListApi.getTags();
    data.items.forEach(e => e.data_info = JSON.parse(e.data_info));
    return data.items;
});

export const tagsSlice = createSlice({
    name: 'tags',
    initialState: {
        tags: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        // setTags: (state, action) => {
        //     state.data = action.payload;
        // },
        // setStatus: (state, action) => {
        //     state.status = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTags.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTags.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.tags = action.payload;
            })
            .addCase(fetchTags.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error.message;
            });
    }
});

// export const getTags = () => {
//     return async (dispatch, getState) => {

//         const { tags } = getState();

//         if (!['INITIAL', 'LOADED'].includes(tags.status)) {
//             return;
//         }

//         dispatch(setStatus('FETCHING'));

//         MyListApi.getTags()
//             .then(data => {
//                 dispatch(setTags(data.items));
//                 dispatch(setStatus('LOADED'));
//             });
//     }
// }

// export const { setTags, setStatus } = tagsSlice.actions;
export default tagsSlice.reducer;