import { Box, Button, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivities, resetStatus } from "../slices/activitiesSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Activity } from "./Activity";

export const Activities = props => {

    const user = useSelector(state => state.login.user);
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const activities = useSelector((state) => state.activities.activities);
    const status = useSelector((state) => state.activities.status);
    const error = useSelector((state) => state.activities.error);
    const [page, setPage] = useState(0);

    const [activitiesFor, setActivitiesFor] = useState('user');

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchActivities({ friends: (activitiesFor === 'user'), page: page }));
        }
    }, [status, page, activitiesFor]);

    let ix = 0;

    const handleNext = () => {
        dispatch(resetStatus());
        setPage(page + 1);
    }

    const handlePrev = () => {
        dispatch(resetStatus());
        setPage(page - 1);
    }

    const temp = Math.floor(activities.count / 5);

    const disablePrev = (page == 0);
    const disableNext = (page + 1) >= (Math.floor(activities.count / 5) + 1);

    const handleActivitiesForChange = (_, val) => {
        dispatch(resetStatus());
        setPage(0);
        setActivitiesFor(val);
    }

    return (
        // <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        //     <Box sx={{ display: 'flex', backgroundColor: 'red' }}>
        //         <Typography>Box 1</Typography>
        //     </Box>
        //     <Box sx={{ display: 'flex', backgroundColor: 'red' }}>
        //         <Typography>Box 2</Typography>
        //     </Box>
        // </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%', minHeight: '400px' }}>
            <Box sx={{ borderBottom: '1px solid #cccccc' }}>
                <Typography variant="h4" sx={{ color: 'black' }}>Activities</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', paddingBottom: '10px' }}>
                <Tabs value={activitiesFor} onChange={handleActivitiesForChange}>
                    <Tab label="FOR YOU" value="user" />
                    <Tab label="EVERYONE" value="everyone" />
                </Tabs>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {status === 'succeeded' ? activities.map(a => {
                return (
                    <Activity activity={a} key={ix++} />
                )
            }) : <CircularProgress />}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                <Button onClick={handlePrev} disabled={disablePrev}>Prev</Button>
                <Typography sx={{ color: 'black' }}>{page + 1}</Typography>
                <Button onClick={handleNext} disabled={disableNext}>Next</Button>
            </Box>
        </Box>
    );
}