import { Chip, Tooltip } from "@mui/material";
import { CountText } from "./CountText";
import CommentIcon from '@mui/icons-material/Comment';

export const CommentChip = ({ count }) => {
    return (
        <Tooltip title="Comments" placement="top" arrow>
            <Chip icon={<CommentIcon fontSize="small" />} label={<CountText num={count || 0} />} size="small" />
        </Tooltip>
    );
}