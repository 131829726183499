import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MyListApi } from "../utils/restApi";

export const fetchComments = createAsyncThunk('comments/fetchComments', async ({ itemId, page }) => {
    const data = await MyListApi.getComments(itemId, page);
    return data.items;
});

export const createComment = createAsyncThunk('comments/createComment', async ({ comment, itemId, commentId = null }) => {
    const data = await MyListApi.createComment(comment, itemId, commentId);
    return data;
});

const commentsSlice = createSlice({
    name: "comments",
    initialState: {
        itemId: null,
        comments: [],
        status: 'idle',
        error: null,
        newCommentStatus: 'idle',
        newCommentError: null,
        // newComment: {
        //     comment: null,
        //     status: 'idle',
        //     error: null,
        // }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchComments.pending, (state, action) => {
                state.status = 'loading';
                state.itemId = action.meta.arg.itemId;
            })
            .addCase(fetchComments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.comments = action.payload;
            })
            .addCase(fetchComments.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error.message;
            })
            .addCase(createComment.pending, (state, action) => {
                state.newCommentStatus = 'loading';
            })
            .addCase(createComment.fulfilled, (state, action) => {
                state.newCommentStatus = 'succeeded';
                state.comments.unshift(action.payload);
            })
            .addCase(createComment.rejected, (state, action) => {
                state.newCommentStatus = 'error';
                state.newCommentError = action.error.message;
            });
    },
});

export default commentsSlice.reducer;
