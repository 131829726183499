import { Badge, Box, Button, Card, CardActions, CardContent, CardHeader, Chip, CircularProgress, Dialog, DialogTitle, Divider, FormLabel, IconButton, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MyListApi } from "../utils/restApi";
import { notification, snakeToCamel, snakeToTitleCase } from "../utils/utils";
import { TagIcon } from "./TagIcon";
import AddCommentIcon from '@mui/icons-material/AddComment';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { Comment } from "./Comment";
import { ItemComments } from "./ItemComments";
import { AddCommentDialog } from "./AddCommentDialog";
import CategoryIcon from '@mui/icons-material/Category';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useDispatch, useSelector } from "react-redux";
import { LabelValue } from "./LabelValue";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { LikeChip } from "./LikeChip";
import { CommentChip } from "./CommentChip";
import { TaskAlt } from "@mui/icons-material";
import { ItemStats } from "./ItemStats";
import { addItemUpdateToItem, fetchItem } from "../slices/itemsSlice2";
import { DatePicker } from "@mui/x-date-pickers";
import { parseISO } from "date-fns";
import { addNotification } from "../slices/notificationSlice";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { useSnackbar } from "notistack";
import { ItemUpdateDialog } from "./ItemUpdateDialog";

const getTypeDefaultValue = type => {
  switch (type) {
    case 'date': return null;
    default: return '';
  }
}

const parseTypeValue = (type, value) => {
  switch (type) {
    case 'date': return parseISO(value);
    default: return value;
  }
}

const displayTypeValue = (type, value) => {
  switch (type) {
    case 'date': return '';
    default: return value;
  }
}



export const ItemDetails = () => {

  console.log('ItemDetails');

  const user = useSelector(state => state.login.user);
  const ref = useRef();
  const { itemId } = useParams();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [itemData, setItemData] = useState({});
  const { items, status, error } = useSelector(state => state.items2);

  // const { enqueueSnackbar } = useSnackbar();
  // const [ itemUpdateOpen, setItemUpdateOpen ] = useState(false);
  // const [ itemUpdate, setItemUpdate ] = useState(null);

  const item = items.find(i => i.id === itemId);
  let dataInfo = [];

  // console.log('item', item);

  useEffect(() => {
    if (!item) {
      dispatch(fetchItem({ itemId: itemId }));
    }
  }, [status, dispatch]);

  dataInfo = JSON.parse(item?.tag?.data_info || '[]');
  const data = JSON.parse(item?.data || '{}');

  useEffect(() => {
    if (item) {
      const newItemData = {};
      dataInfo.forEach(di  => {
        const { name, type } = di;
        const value = data[di.name] || getTypeDefaultValue(type);
        newItemData[`${name}`] = parseTypeValue(type, value);
      });
      setItemData(newItemData);
    }
  }, [item]);

  if (!item || status !== 'succeeded') {
    return <Box></Box>
  }

  const resetFormData = (dataInfo, data) => {
    const newItemData = {};
    dataInfo.forEach(di => {
      const { name, type } = di;
      const value = data[name] || getTypeDefaultValue(type);
      newItemData[`${name}`] = parseTypeValue(type, value);
    });
    setItemData(newItemData);
  }

  const handleChange = name => {
    // const { name, type } = dataInfo[index];
    const di = dataInfo.find(di => di.name === name);
    switch (di.type) {
      case 'date':
        return (e) => setItemData({ ...itemData, [`${name}`]: e });
      default:
        return (e) => setItemData({ ...itemData, [`${name}`]: e.target.value });
    }
  }

  // console.log('itemData', itemData);

  const handleCancel = () => {
    resetFormData(dataInfo, data);
    setEdit(false);
  }

  const handleSave = () => {
    const changedValues = [];
    dataInfo.forEach((di, ix) => {
      const { name, type } = di;

      const parseFormValue = value => {
        // console.log('value', value);
        // console.log('typeof value', typeof value);
        // console.log('value?.constructor?.name', value?.constructor?.name);
        if (typeof value === 'object' && value?.constructor?.name === 'Date') {
          return isNaN(value) ? '' : value.toISOString();
        }
        return value || '';
      }

      const newValue = parseFormValue(itemData[name]);
      const curValue = data[name] || '';

      // console.log('name', name);
      // console.log('newValue', newValue);
      // console.log('curValue', curValue);
      // console.log('typeof newValue', typeof newValue);

      if (newValue !== curValue) {
        changedValues.push(name);
      }
    });
    if (changedValues.length == 0) {
      notification("No changes detected.", "info");
    } else {
      changedValues.forEach(n => {
        MyListApi.createItemUpdate(item.id, n, itemData[n])
          .then(data => {
            dispatch(addItemUpdateToItem(item.id, ))
            notification(data.data, "success");
          })
          .catch(error => {
            notification(error.message, "error");
          });
      });
    }
  }

  // console.log('item', item);

  // const handleDialogClose = () => {
  //   setItemUpdateOpen(false);
  // }
  // console.log('AAA itemData', itemData);

  return (
    <Box>
      {/* <Dialog open={itemUpdateOpen} onClose={handleDialogClose}>
        <DialogTitle>Item Details Update</DialogTitle>
        <Typography></Typography>
        <Button color="error">Reject</Button>
        <Button color="primary">Accept</Button>
      </Dialog> */}
      <ItemUpdateDialog ref={ref} itemData={itemData} dataInfo={dataInfo} />
      <Paper sx={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px' }}>
        <Box>
          <Typography variant="h4">{item.name}</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        {dataInfo.map((d, ix) => {
          // const name = d.name;
          // const type = d.type;
          const { name, type, values = [] } = d;

          const parseFieldValue = (type, value) => {
            // console.log('type', type);
            // console.log('value', value);
            // return value || '';
            switch (type) {
              case 'date': return new Date(value).toDateString();
              default: return value || '';
            }
          }

          let value = parseFieldValue(type, data[name]); //data[name] || '';

          if (!value && !edit) return undefined;

          let inputField = null;

          const hasUpdate = item.item_updates.some(iu => iu.property === name);
          const itemUpdate = hasUpdate ? item.item_updates.find(iu => iu.property === name) : null;

          switch (type) {
            case 'date':
              inputField =
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <DatePicker
                    slotProps={{ textField: { variant: 'filled' }}}
                    label={name}
                    onChange={handleChange(name)}
                    value={itemData[`${name}`]}
                    sx={{ width: '100%' }}
                  />
                    <IconButton onClick={() => setItemData({ ...itemData, [`${name}`]: null })} sx={{ width: '24px', height: '24px' }}><ClearIcon /></IconButton>
                  </Box>
              break;
            case 'enum':
              inputField =
                <TextField
                  select
                  variant="filled"
                  onChange={handleChange(name)}
                  value={itemData[`${name}`]}
                  fullWidth
                >
                  <MenuItem v=''><em>none</em></MenuItem>
                  {values.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                </TextField>
              break;
            default:
              inputField =
                  <TextField variant="filled" size="small" sx={{ height: '50px' }} label={name} value={itemData[`${name}`]} onChange={handleChange(name)} />
              break;
          }

          return (
            <Box key={ix} sx={{ display: 'flex', flexDirection: 'row', gap: '4px', height: '50px', flex: '1 1 50%' }}>
              {edit ?
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', width: '100%' }}>
                    {inputField}
                    {hasUpdate ? <IconButton onClick={() => ref.current.openItemUpdateDialog(itemUpdate)}><SyncProblemIcon /></IconButton> : undefined}
                </Box>
                :
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography fontSize="12px" sx={{ fontWeight: "bolder", color: "gray" }}>{name.toUpperCase()}</Typography>
                  <Typography>{value}</Typography>
                </Box>
              }
            </Box>
          );
          })
        }
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {user ? (edit ?
            <>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleSave}>Save</Button>
            </>
          :
            <Button onClick={() => setEdit(true)}>Edit</Button>
          ) : undefined}
        </Box>
        <Box>
          <pre>{item.data}</pre>
        </Box>
        <Box sx={{ borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '5px 0 5px 0', display: 'flex', flexDirection: 'row', gap: '5px' }}>
          <ItemStats item={item} />
        </Box>
        <ItemComments itemId={item.id} />
      </Paper>
    </Box>
  );
}