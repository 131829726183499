import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export const SideMenuIcon = ({ title, icon, to, preNav = () => {} }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const paths = location.pathname.split('/');
    const p = paths[1];
    const enabled = (to === p);

    const clickHandler = () => {
        preNav();
        navigate(`/${to}`);
    }

    return (
        <Tooltip title={title} placement='left'>
            <IconButton size='large' onClick={clickHandler}
                sx={{
                    color: '#FF5733',
                    '&:hover': {
                        backgroundColor: 'rgba(235, 69, 17, 0.2)',
                    },
                    marginLeft: 'auto',
                }}
            >
                {React.cloneElement(icon, {
                    fontSize: 'large',
                    sx: {
                        color: enabled ? '#53d8fb' : '#6969b3',
                    }
                })}
            </IconButton>
        </Tooltip>
    );
}