import { Avatar, Divider, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography } from "@mui/material";
import { useState } from "react";
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../slices/loginSlice";
// import { useAuth0 } from "@auth0/auth0-react";
import { logout } from '../slices/loginSlice';

export const UserMenu = ({ user }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const user = useSelector((state) => state.login.user);

    // const { logout } = useAuth0();
    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    const handleClick = e => {
        setAnchor(e.currentTarget);
    }

    const handleClose = () => {
        setAnchor(null);
    }

    const handleProfile = () => {
        setAnchor(null);
        navigate(`/user/${user.id}/items`);
    }

    const handleLogout = () => {
        setAnchor(null);
        navigate('/');
        dispatch(logout());
    }

    const handleLogin = () => {
        setAnchor(null);
        navigate('/login');
    }

    return (
        <div>
            <div style={{
                display: "flex",
                alignItems: "center",
            }}>
                {user ? <Typography color="wheat">{`@${user.display_name}`}</Typography> : undefined}
                <IconButton onClick={handleClick} ><PersonIcon htmlColor="lightgray" /></IconButton>
            </div>

            <Menu
                id="user-menu"
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
            >
                {user ?
                    <MenuList>
                        <MenuItem onClick={handleProfile}>
                            <ListItemIcon><AccountBoxIcon fontSize="small"/></ListItemIcon>
                            <ListItemText>Profile</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon><LogoutIcon /></ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </MenuItem>
                    </MenuList> :
                    <MenuList>
                        <MenuItem onClick={handleLogin}>
                            <ListItemIcon><AccountBoxIcon fontSize="small"/></ListItemIcon>
                            <ListItemText>Login</ListItemText>
                        </MenuItem>
                    </MenuList>
                }
            </Menu>
        </div>
    );
}