import { Avatar, Box, Divider, Link, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { DateText } from "./DateText";

export const Comment = ({ comment }) => {
    parseISO(comment.created_at)
    const date = format(parseISO(comment.created_at), 'MMM Lo, yyyy K:mmaaa');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', borderBottom: "1px solid lightgray", paddingBottom: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', minHeight: '50px' }}>
                <Box><Avatar>{comment.created_by.display_name.slice(0, 2).toUpperCase()}</Avatar></Box>
                <Box>
                    <Typography sx={{ minHeight: '50px' }}>{comment.text}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
                        <DateText label="Posted" date={parseISO(comment.created_at)} />
                        <Typography variant="caption">by <Link underline="hover" href={`/users/${comment.created_by.id}`}>{`@${comment.created_by.display_name}`}</Link></Typography>
                    </Box>
                </Box>
            </Box>
        </Box>


        // <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        //     <Box><Avatar>{comment.created_by.name.slice(0, 2).toUpperCase()}</Avatar></Box>
        //     <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        //         <Box>{comment.text}</Box>
        //     </Box>
        //     <Box>
        //         <Typography>{date}</Typography>
        //     </Box>
        // </Box>

        // <div style={{
        //     padding: "10px",
        //     borderBottom: "1px solid #eeeeee",
        // }}>
        //     <div>{comment.text}</div>
        //     <div style={{ fontSize: "0.7em" }}>{`@${comment.created_by.name} - ${date}`}</div>
        // </div>
    );
}