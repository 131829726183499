import {
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Link,
  Typography,
  } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import { DateText } from './DateText';
import { CountText } from './CountText';
import { format, parseISO } from "date-fns";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { LikeChip } from './LikeChip';
import { CommentChip } from './CommentChip';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import { ItemStats } from './ItemStats';
import { TagIcon } from './TagIcon';
import { ItemTitle } from './ItemTitle';

export const Item = ({ item, canDoAction, user }) => {

  // const sortedActionStats = (item.action_stats ? [...item.action_stats] : []).sort((a, b) => a.action.localeCompare(b.action));
  // console.log('item', item);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <DoubleArrowRoundedIcon /><Typography variant='subtitle2'>{`${item.tag.name.toUpperCase()}`}</Typography>
      </Box> */}
      {/* <Box sx={{ padding: '20px 0 20px 0', display: 'flex', flexDirection: 'row', gap: '5px' }}> */}
        {/* <TagIcon name={item.tag.name} /> */}
        {/* <Link href={`/items/${item.id}`} underline='hover'><Typography variant='h5'>{item.title}</Typography></Link> */}
      {/* </Box> */}
      <ItemTitle item={item} />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <ItemStats item={item} />
      </Box>
    </Box>


    // <div key={item.id} style={{
    //   padding: "16px",
    //   borderBottom: "1px solid lightgray",
    // }}>
    //   <div style={{
    //       // paddingBottom: "0px",
    //   }}>
    //       <Link underline="hover" href={`/items/${item.id}`}>{item.title}</Link>
    //       {/* <div><DateText date={parseISO(item.created_at)}/>&nbsp;<Link href={`/user/${item.created_by.name}`} variant="caption">{`@${item.created_by.name}`}</Link><Typography variant="caption">{`${!tag ? ` >${item.tag.name}` : ``}`}</Typography></div> */}
    //   </div>
    //   <div style={{
    //       display: "flex",
    //       gap: "4px",
    //       alignItems: "center",
    //   }}>
    //       {/* {user ?
    //           <IconButton size="small" onClick={() => handleAddAction(user.id, item.id)}><AddCircleOutlineIcon fontSize="small" /></IconButton>
    //       : null} */}
    //       {item.action_stats ? item.action_stats.sort((a, b) => a.action.localeCompare(b.action)).map(a => {
    //           const key = `action-stat-id-${a.action}`;
    //           const label = <><span>{a.action}</span>&nbsp;<CountText num={a.count} /></>
    //           const color = item.actions && item.actions.includes(a.action) ? 'primary' : 'default';
    //           return <Chip key={key} label={label} size="small" color={color} />
    //       }) : null}
    //       <Chip icon={<ThumbUpIcon fontSize="small" color="info" />} label={<CountText num={item.like_count} />} size="small" />
    //       <Chip icon={<CommentIcon fontSize="small" color="info" />} label={<CountText num={item.comment_count} />} size="small" />
    //   </div>
    // </div>
  )
};