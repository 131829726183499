import {useDispatch, useSelector} from 'react-redux';
import { UserMenu } from './UserMenu';
import { Box, IconButton, Link, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { logout } from '../slices/loginSlice';
import { useNavigate } from 'react-router-dom';

const Header = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.user);

  const isLoggedIn = !!user;

  const clickHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid white', padding: '8px' }}>
      <Box sx={{ flexGrow: 1 }}>
      </Box>
      <Box sx={{ flexGrow: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant='h5' color="white">nosi.io</Typography>
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center'  }}>
        <UserMenu user={user} />
      </Box>
    </Box>
    // <div style={{
    //   display: "flex",
    //   width: "100%",
    //   borderBottom: "1px solid gray",
    //   height: "40px",
    // }}>
    //   <div style={{
    //     flex: "1",
    //   }}>
    //   </div>
    //   <div style={{
    //     display: "flex",
    //     flex: "1",
    //     alignItems: "center",
    //     width: "100%",
    //     justifyContent: "center",
    //   }}>
    //     <Typography variant="h5">nosi</Typography>
    //   </div>
    //   <div style={{
    //     display: "flex",
    //     flex: "1",
    //     justifyContent: "flex-end",
    //     width: "100%",
    //     alignItems: "center",
    //   }}
    //   >
    //     {isLoggedIn ?
    //       <UserMenu user={user} />
    //     : null}
    //     {!user ?
    //         <Link href="/login" underline="hover">Login</Link>
    //     : null}
    //   </div>
    // </div>
  );
}

export default Header;