import { useEffect, useState } from "react";
import { Comment } from "./Comment";
import { MyListApi } from "../utils/restApi";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import AddCommentIcon from '@mui/icons-material/AddComment';
import { useDispatch, useSelector } from "react-redux";
import { createComment, fetchComments } from "../slices/commentsSlice";

const MAX_COMMENT_LENGTH = 256;
const MAX_NEW_LINES = 4;

export const ItemComments = ({ itemId, initiateLoad }) => {

    const user = useSelector(state => state.login.user);
    const dispatch = useDispatch();
    const [newComment, setNewComment] = useState('');
    const comments = useSelector((state) => state.comments.comments);
    const status = useSelector((state) => state.comments.status);
    const error = useSelector((state) => state.comments.error);
    const newCommentStatus = useSelector((state) => state.newCommentStatus);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchComments({ itemId: itemId, page: 0 }));
        }
    }, [status, newCommentStatus, dispatch]);

    const handleComment = e => {
        const s = e.target.value;
        const newLineCount = s.split("\n").length;
        if (s.length <= MAX_COMMENT_LENGTH && newLineCount <= MAX_NEW_LINES) {
            setNewComment(e.target.value);
        }
    }

    const postComment = () => {
        dispatch(createComment({ comment: newComment, itemId: itemId }));
        setNewComment('');
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', borderBottom: "1px solid lightgray" }}>
                <Box>
                    <Avatar>{user ? user.display_name.slice(0, 2).toUpperCase() : ''}</Avatar>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <TextField multiline rows={4} value={newComment} onChange={handleComment} sx={{ minWidth: '300px', width: '100%' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: '5px 0 15px 0' }}>
                        <Box flex={1} sx={{ alignContent: "center" }}>
                            <Typography>{`${newComment.length}/${MAX_COMMENT_LENGTH}`}</Typography>
                        </Box>
                        <Box flex={1} sx={{ alignContent: "center", textAlign: "right" }}>
                            <Button variant="contained" size="small" endIcon={<AddCommentIcon />} onClick={postComment}>Post</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {status === 'succeeded' ? comments.map(c => <Comment key={c.id} comment={c} />) : undefined}
        </Box>
    );
}