import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { fetchTags } from "../slices/tagsSlice";

export const TagChooser = ({ curTag = '', onSelect, sx = {} }) => {


    const dispatch = useDispatch();
    // const [anchor, setAnchor] = useState(null);
    // const [open, setOpen] = useState(false);

    // const tags = useSelector(state => state.tags.tags);
    // const tagsStatus = useSelector(state => state.tags.status);
    // const tagsError = useSelector(state => state.tags.error);

    const { tags, status, error } = useSelector(state => state.tags);

    // console.log('status', status);

    const ct = tags.find(t => t.name === curTag)?.name || ''; //tags.includes(curTag) ? curTag : '';
    const [tag, setTag] = useState(ct);

    useEffect(() => {
        if (status == 'idle') {
            // console.log('dispatch fetchTags()');
            dispatch(fetchTags());
        }
    }, [dispatch]);

    // const handleClick = e => {
    //     setAnchor(e.target);
    //     setOpen(!open);
    // }

    // const handleChoice = choice => {
    //     setOpen(false);
    //     setTag(choice);
    //     if (onSelect) onSelect(choice);
    // }

    const handleChange = (event) => {
        setTag(event.target.value);
        if (onSelect) {
            onSelect(tags.find(t => t.name === event.target.value));
        }
    }

    return (
        <Box sx={sx}>
            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
                <InputLabel>Tag</InputLabel>
                <Select
                    value={tag}
                    onChange={handleChange}
                >
                    <MenuItem value=''><em>none</em></MenuItem>
                    {tags.map(t => {
                        return (
                            <MenuItem key={t.id} value={t.name}>{t.name}</MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}