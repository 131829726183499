import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification, removeNotification } from "../slices/notificationSlice";
import CancelIcon from '@mui/icons-material/Cancel';

export const Notifications = props => {

    const dispatch = useDispatch();
    // const [ix, setIx] = useState(0);
    // const [open, setOpen] = useState(true);
    // const open = useSelector(state => state.notification.open);
    // const message = useSelector(state => state.notification.message);
    // const type = useSelector(state => state.notification.type);

    const notifications = useSelector(state => state.notification.notifications);

    // console.log('notifications', notifications);

    const handleClose = id => {
        // if (reason == 'clickaway') {
        //     return;
        // }
        // // setOpen(false);
        // dispatch(closeNotification());
        dispatch(closeNotification(id));
        setTimeout(() => dispatch(removeNotification(id), 200));
    }

    const action = <><Button onClick={handleClose}><CancelIcon /></Button></>

    return (
        <>
            {notifications.map(({ id, message, type, open }) => {
                return <Snackbar
                    key={id}
                    open={open}
                    autoHideDuration={10000}
                    onClose={() => handleClose(id)}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                >
                    <Alert severity={type} onClose={() => handleClose(id)}>{message}</Alert>
                </Snackbar>
            })}
            {/* <Snackbar
                open={open}
                // autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                action={action}
            >
                <Alert severity={type} onClose={handleClose}>{message}</Alert>
            </Snackbar> */}
        </>
    );
}