import { Autocomplete, Box, Button, Card, CardActions, CardContent, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import { ItemPropertyField } from "./ItemPropertyField";
import { useState } from "react";
import { TagSelect } from "./TagSelect";
import { useDispatch, useSelector } from "react-redux";
import { MyListApi } from "../utils/restApi";
import { setUserStatus } from "../slices/itemsSlice";
import { TagChooser } from "./TagChooser";
import { DatePicker } from "@mui/x-date-pickers";
import CancelIcon from '@mui/icons-material/Cancel';

const getDefaultVal = type => {
    if (type === 'string' || type === 'enum') return '';
    return null;
}

export const ItemCreate = props => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.login.user);
    const [searchParams] = useSearchParams();
    // const name = searchParams.get('name');
    const [properties, setProperties] = useState({});
    const [selectedTag, setSelectedTag] = useState();
    const [name, setName] = useState('');

    const handleSubmit = e => {
        e.preventDefault();

        const propCount = selectedTag?.data_info.length || 0;


        const data = {};
        for (let ix = 0; ix < propCount; ix++) {
            const name = selectedTag?.data_info[`${ix}`].name;
            const type = selectedTag?.data_info[`${ix}`].type;
            const p = (type === 'date') ? properties[`${ix}`].toISOString() : properties[`${ix}`];
            data[name] = p;
        }

        // console.log('name', name);
        // console.log('data', data);

        // const { name } = e.target.elements;

        // const data = {};
        // for (let ix = 0; ix < properties.length; ix++) {
        //     const name = e.target.elements[`prop-name-${ix}`].value;
        //     const value = e.target.elements[`prop-value-${ix}`].value;
        //     data[name] = value;
        // }

        MyListApi.createItem(selectedTag.id, name, data)
            .then(() => {
                dispatch(setUserStatus('INITIAL'))
            })
            .catch(() => {
            });
    }

    const handleTagSelect = (val) => {
        // console.log('val', val);
        setSelectedTag(val);
        const count = val?.data_info.length || 0;
        const newProperties = {};
        for (let ix = 0; ix < count; ix++) {
            const type = val?.data_info[ix].type;
            newProperties[`${ix}`] = getDefaultVal(type);
        }
        setProperties(newProperties);
    }

    const handleChange = index => {
        const type = selectedTag?.data_info[index].type;
        if (type == 'string' || type == 'enum' || type == "integer") {
            return (e) => {
                // console.log('e', e);
                const val = e.target.value;
                setProperties({ ...properties, [`${index}`]: val})
            }
        }
        return (e) => {
            setProperties({ ...properties, [`${index}`]: e})
        }
    }

    return (
        <Box sx={{ width: '100%', }}>
            <form onSubmit={handleSubmit}>
                <Paper sx={{ display: 'flex', flexDirection: 'column', rowGap: '20px', padding: '20px'}}>
                    <Box>
                        <Typography variant="h4">Create Item</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <TagChooser sx={{ width: '100%' }} onSelect={handleTagSelect} />
                        <TextField id="name" label="Name" source="name" variant="filled" size="small" required={true} value={name} onChange={e => setName(e.target.value)} />
                    </Box>
                    <Box>
                        <Typography variant="h6">Item Details</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {(selectedTag?.data_info || []).map((p, index) => {
                                    // console.log('p', p);
                                    // console.log('index', index);
                                    const id = `prop-id-${index}`;
                                    const label = p.name;
                                    // console.log('id', id);
                                    let inputElement = <Box></Box>;
                                    switch (p.type) {
                                        case 'string': inputElement = <TextField variant='filled' label={label} id={id} onChange={handleChange(index)} value={properties[`${index}`]} sx={{ width: '100%' }} />; break;
                                        case 'integer': inputElement = <TextField variant="filled" label={label} id={id} onChange={handleChange(index)} value={properties[`${index}`]} sx={{ width: '100%' }} />; break;
                                        case 'date': inputElement = <DatePicker slotProps={{ textField: { variant: 'filled' }}} label={label} id={id} onChange={handleChange(index)} value={properties[`${index}`]} sx={{ width: '100%' }} />; break;
                                        case 'enum':
                                            inputElement =
                                                <TextField
                                                    select
                                                    variant="filled"
                                                    onChange={handleChange(index)}
                                                    value={properties[`${index}`]}
                                                    label={label}
                                                    sx={{ width: '100%' }}
                                                >
                                                    <MenuItem v=''><em>none</em></MenuItem>
                                                    {p.values.map((v, index) => <MenuItem key={index} value={v}>{v}</MenuItem>)}
                                                </TextField>
                                                // <>
                                                //     <InputLabel id={id}>{label}</InputLabel>
                                                //     <Select labelId={id}  variant='filled' label={label} id={id} onChange={handleChange(index)} value={properties[`${index}`]} sx={{ width: '100%' }}>
                                                //         <MenuItem v=''><em>none</em></MenuItem>
                                                //         {p.values.map((v, index) => <MenuItem key={index} value={v}>{v}</MenuItem>)}
                                                //     </Select>
                                                // </>
                                            break;
                                    }
                                    return (
                                        <Box key={index}>
                                            {inputElement}
                                        </Box>
                                    )
                                })
                            }
                            {/* {JSON.stringify(properties)} */}
                            {/* <TextField variant="filled" label="Release Date" id={`property-value-${0}`} />
                            <Select
                                value={test}
                            >
                                <MenuItem value="action">action</MenuItem>
                                <MenuItem value="comedy">comedy</MenuItem>
                                <MenuItem value="horror">horror</MenuItem>
                                <MenuItem value="romance">romance</MenuItem>
                                <MenuItem value="sci-fi">sci-fo</MenuItem>
                            </Select> */}
                        </Box>
                        {/* {properties.map((p, index) => {
                            return <ItemPropertyField property={p} index={index} key={index} onRemove={handleRemoveProperty} />
                        })}
                        <ItemPropertyField isAdd={true} onAdd={handleAddProperty} /> */}
                    </Box>
                </Paper>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%', margin: '10px 0 0 0', justifyContent: 'right' }}>
                    <Button startIcon={<CancelIcon />} variant="contained" color="secondary" onClick={() => navigate(-1)}>Cancel</Button>
                    <Button startIcon={<SaveIcon />} variant="contained" type="submit">Save</Button>
                </Box>
                {/* <Box>
                    <TextField
                        select
                        label="Genre"
                        variant="filled"
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </TextField>
                </Box> */}
            </form>
        </Box>

        // <form onSubmit={handleSubmit}>
        //     <Card sx={{ padding: "20px", }}>
        //         <CardContent>
        //             <Grid container item xs={12} direction="row" spacing={4}>
        //             <Grid item xs={12}>
        //                 <Typography variant="h4">Create New Item</Typography>
        //             </Grid>
        //             <Grid item xs={12}>
        //                 {/* <TagSelect tagId={tagId} onChange={handleTagSelect} /> */}
        //                 <TagChooser />
        //             </Grid>
        //             <Grid item xs={12}>
        //                 <TextField id="name" label="Name" source="name" defaultValue={name} fullWidth />
        //             </Grid>
        //             <Grid item xs={12}>
        //                 <Typography variant="h6">Item Properties</Typography>
        //             </Grid>
        //             {properties.map((p, index) => (
        //                 <Grid item xs={12} key={index}>
        //                     <ItemPropertyField property={p} index={index} onRemove={handleRemoveProperty} />
        //                 </Grid>
        //             ))}
        //             <Grid item xs={12}>
        //                 <ItemPropertyField isAdd={true} onAdd={handleAddProperty} />
        //             </Grid>
        //             </Grid>
        //         </CardContent>
        //         <CardActions>
        //             <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>Cancel</Button>
        //             <Button startIcon={<SaveIcon />} variant="contained" type="submit">Save</Button>
        //         </CardActions>
        //     </Card>
        // </form>
    );
}