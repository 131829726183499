
import { Box } from '@mui/material';

const Footer = () => {

  return (
    <Box sx={{ height: '100%' }}>
    </Box>
  );
}

export default Footer;