import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Box, Chip, IconButton, Menu, MenuItem } from "@mui/material";
import { LikeChip } from "./LikeChip";
import { CommentChip } from "./CommentChip";
import { TaskAlt } from "@mui/icons-material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { addActionToItem, updateItemAction } from "../slices/itemsSlice2";

export const ItemStats = ({ item }) => {

    const user = useSelector(state => state.login.user);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const likeCount = item.like_count;
    const commentCount = item.comment_count;
    const actionStats = item.action_stats || [];
    const userActions = item.actions || [];

    const handleAddAction = e => {
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleMenu = action => {
        setAnchorEl(null);
        // dispatch(addActionToItem({ itemId: item.id, actionName: action }));
        dispatch(updateItemAction({ itemId: item.id, action: action }));
    }

    // console.log('item', item);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: "center" }}>
            <LikeChip count={likeCount} />
            <CommentChip count={commentCount} />
            {actionStats.map((a, ix) => {
                const color = userActions.includes(a.action) ? "primary" : "default";
                return (
                    <Chip key={ix} color={color} icon={<TaskAlt fontSize="small" />} label={`${a.action} by ${a.count} users`} sx={{ height: '24px' }} />
                );
            })}
            {user ?
                <Box>
                    <IconButton onClick={handleAddAction}><AddCircleOutlineIcon fontSize="small" /></IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {item.tag.actions.map((a, ix) => {
                            const disabled = userActions.includes(a.name);
                            return (
                                <MenuItem key={ix} disabled={disabled} onClick={() => handleMenu(a.name)}>{a.name}</MenuItem>
                            );
                        })}
                        {/* <MenuItem>watched</MenuItem>
                        <MenuItem>finished</MenuItem> */}
                    </Menu>
                </Box>
            : undefined}
        </Box>
    );
}