import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { SideMenuIcon } from './SideMenuIcon';
// import { resetStatus } from '../slices/itemsSlice2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import StarsIcon from '@mui/icons-material/Stars';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { resetStatus as resetStatusActivities } from '../slices/activitiesSlice';
import { resetStatus as resetStatusItems } from '../slices/itemsSlice2';

export const SideMenu = () => {
    const user = useSelector(state => state.login.user);
    const dispatch = useDispatch();

    const resetAll = () => {
        // dispatch(resetStatusActivities());
        // dispatch(resetStatusItems());
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <SideMenuIcon title='Popular' icon={<StarsIcon />} to='popular' />
            <SideMenuIcon title='Activities' icon={<TaskAltIcon />} to='activities' />
            <SideMenuIcon title='Search' icon={<ManageSearchIcon />} to='browse' />
            {user ? <SideMenuIcon title='Create Item' icon={<AddCircleIcon />} to='create-item' /> : undefined}
        </Box>
    );
}
