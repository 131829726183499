import { Avatar, Box, Breadcrumbs, Card, CardContent, Chip, Link, Typography } from '@mui/material';
import { DateText } from './DateText';
import { parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { Item } from './Item';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export const Activity = ({ activity }) => {

    const user = useSelector(state => state.login.user);

    let icon = null;
    let label = null;
    let eventUser = null;
    let item = null;

    switch (activity.type) {
        case "UserItem":
            icon = <TaskAltIcon color="info" />;
            label = activity.event.action;
            eventUser = activity.event.user;
            item = activity.event.item;
            break;
        case "Like":
            icon = <ThumbUpIcon color="info" />;
            label = "liked";
            break;
        case "Comment":
            icon = <CommentIcon color="info" />;
            label = "commented";
            eventUser = activity.event.created_by;
            item = activity.event.item;
            break;
        case "Item":
            icon = <AddCircleIcon color="info" />;
            label = "created";
            eventUser = activity.event.created_by;
            item = activity.event;
            break;
    }

    const isLoggedInUser = eventUser.id === user?.id;

    return (
        <Card>
            <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: '0px' }}>
                        <Breadcrumbs separator="›">
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                <Avatar sx={{ width: 24, height: 24 }} />
                                {isLoggedInUser ?
                                    <Typography>you</Typography> :
                                    <Link href={`/users/${eventUser.id}`} underline="hover" fontSize={16}>@{eventUser.display_name}</Link>
                                }
                            </Box>
                            <Chip icon={icon} label={label} size="small" />
                            <DateText label="" date={parseISO(activity.event.created_at)} />
                        </Breadcrumbs>
                    </Box>
                    {activity.type == 'Comment' ?
                        <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                            <FormatQuoteIcon sx={{ color: '#bbbbbb' }} />
                            <Typography component="blockquote" variant="h6" sx={{ color: '#999999'}} >{activity.event.text}</Typography>
                        </Box>
                    : undefined }
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', columnGap: '10px', rowGap: '20px', borderTop: '1px solid #eeeeee', padding: '20px 0 0 0' }} >
                        {item ? <Item item={item} /> : undefined}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}