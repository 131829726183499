import { Button } from "@mui/material";
import { FriendsList } from "./FriendsList";
import { useEffect, useRef, useState } from "react";
import { MyListApi } from "../utils/restApi";
import { useDispatch, useSelector } from "react-redux";
import { RequestFriendDialog } from "./RequestFriendDialog";
import { addNotification } from "../slices/notificationSlice";

export const Friends = () => {

    const user = useSelector(state => state.login.user);
    const ref = useRef();
    const dispatch = useDispatch();
    const [friends, setFriends] = useState({
        items: [],
        count: 0,
        page: 0,
        perPage: 0,
    });

    useEffect(() => {
        MyListApi.getFriends(user.id)
            .then(data => {
                setFriends(data);
            })
            .catch(error => {
                dispatch(addNotification({
                    message: error.message,
                    type: 'error',
                }));
            });
    }, []);

    return (
        <div>
            <div style={{
                display: "flex",
                alignContent: "flex-end",
                width: "100%",
                flexDirection: "row",
                // flexWrap: "wrap",
                flexFlow: "row-reverse",
                gap: "4px",
            }}>
                <Button variant="contained" size="small" onClick={() => {}}>Block User</Button>
                <Button variant="contained" size="small" onClick={() => ref.current.openRequestFriend()}>Add Friend</Button>
            </div>
            <RequestFriendDialog ref={ref} />
            <FriendsList friends={friends.items} />
        </div>
    );
}