import { useState } from "react";
import { Box, Button, Menu, MenuItem, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { notification } from "../utils/utils";

export const Settings = props => {

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const open = Boolean(anchorEl);

    const handleTest = () => {
        notification("This is a notification!", "error");
    }

    return (
        <Paper>
            <Box>
                <Button onClick={handleClick}>Add</Button>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem>Open</MenuItem>
                    <MenuItem>Close</MenuItem>
                </Menu>
            </Box>
            <Box>
                <Button onClick={handleTest}>TEST</Button>
            </Box>
        </Paper>
    );
}