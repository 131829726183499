import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MyListApi } from "../utils/restApi";

export const fetchItems = createAsyncThunk('items2/fetchItems', async ({ userId, tag, name, sort, isPopular, page, perPage = 5 }) => {
    // console.log('fetchItems');
    const data = await MyListApi.getItems(userId, tag, name, sort, isPopular, page, perPage);
    // console.log('data', data);
    return data;
});

export const fetchPopularItems = createAsyncThunk('items2/fetchPopularItems', async ({ tag, page, perPage = 5}) => {
    // console.log('fetchPopularItems');
    const data = await MyListApi.getItems(null, tag, null, null, true, page, perPage);
    return data;
});

export const updateItemAction = createAsyncThunk('items2/updateItemAction', async ({ itemId, action }, { dispatch }) => {
    // console.log('updateItemAction');
    const data = await MyListApi.collectItem(itemId, action);
    dispatch(addActionToItem({ itemId: itemId, actionName: action }));
    return data;
});

export const fetchItem = createAsyncThunk('items2/fetchItem', async ({ itemId }) => {
    // console.log('fetchItem');
    const data = await MyListApi.getItem(itemId);
    return data;
});

const items2Slice = createSlice({
    name: 'items2',
    initialState: {
        items: [],
        count: 0,
        status: 'idle',
        error: null,
    },
    reducers: {
        resetStatus: (state) => {
            state.status = 'idle';
            state.items = [];
            state.count = 0;
            state.error = null;
        },
        addActionToItem: (state, action) => {
            const { itemId, actionName } = action.payload;
            const item = state.items.find(i => i.id === itemId);
            if (item) {
                const actionStat = item.action_stats.find(as => as.action === actionName);
                if (actionStat) {
                    actionStat.count++;
                } else {
                    item.action_stats.push({ action: actionName, count: 1 });
                }
                if (!item.actions.includes(actionName)) {
                    item.actions.push(actionName);
                }
            }
        },
        addItemUpdateToItem: (state, action) => {
            const { itemId, itemUpdate } = action.payload;
            const item = state.items.find(i => i.id === itemId);
            if (item) {
                item.item_updates.push(itemUpdate);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchItems.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchItems.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload.items;
                state.count = action.payload.count;
                state.page = action.payload.page;
            })
            .addCase(fetchItems.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.error.message;
            })

        builder
            .addCase(fetchPopularItems.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchPopularItems.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload.items;
                state.count = action.payload.count;
                state.page = action.payload.page;
            })
            .addCase(fetchPopularItems.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.error.message;
            });

        builder
            .addCase(updateItemAction.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(updateItemAction.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(updateItemAction.rejected, (state, action) => {
                state.status = 'error';
            });

        builder
            .addCase(fetchItem.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchItem.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = [action.payload];
                state.count = 1;
                state.page = 0;
            })
            .addCase(fetchItem.rejected, (state, action) => {
                state.status = 'error';
            })
    }
})

export const { resetStatus, addActionToItem, addItemUpdateToItem } = items2Slice.actions;
export default items2Slice.reducer;