
// const host = 'http://localhost:8085';
const host = window.env.REACT_APP_MYLIST_API_HOST || 'http://localhost:8085';

const api = (method, url, body, auth = true) => {
    const options = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const token = JSON.parse(window.localStorage.getItem('token'));
    if (auth && token !== null) {
        options.headers.Authorization = `Bearer ${token}`;
    }
    if (body) {
        options.body = body;
    }
    return fetch(((typeof url) === "object" ? url : ((new URL(`${host}${url}`)))).toString(), options);
}

export const MyListApi = {

    login: async (username, password) => {
        const url = `/api/v1/login`;
        const body = JSON.stringify({
            username: username,
            password: password,
        });
        return api('PUT', url, body, false)
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.data);
            })
            .then(data => {
                if ('error' in data) {
                    return Promise.reject(data);
                }
                return Promise.resolve(data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            });
    },

    auth: async (username, password) => {
        const url = `/api/v1/auth`;
        const body = JSON.stringify({
            username: username,
            password: password
        });
        return api('POST', url, body, false)
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.data);
            })
            .then(data => {
                if ('error' in data) {
                    return Promise.reject(data);
                }
                return Promise.resolve(data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            });
    },

    authMe: async (token) => {
        const url = `/api/v1/auth/me`;
        return api('GET', url)
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.data);
            })
            .then(data => {
                if ('error' in data) {
                    return Promise.reject(data);
                }
                return Promise.resolve(data.data);
            })
            .catch(error => {
                return Promise.reject(error);
            })
    },

    getItems: async (userId, tag = null, name = null, sort = ['createdAt', 'DESC'], isPopular = false, page = 0, perPage = 10) => {
        const url = new URL(`${host}/api/v1/items`);
        const filter = {};

        if (userId) filter.user_id = userId;
        if (tag) filter.tag = tag;
        if (name) filter.name = name;

        url.searchParams.set('filter', JSON.stringify(filter));
        url.searchParams.set('page', page);
        url.searchParams.set('per_page', perPage);
        if (isPopular) {
            url.searchParams.set('is_popular', true);
        }
        if (sort) {
            url.searchParams.set('sort', JSON.stringify(sort));
        }

        return api('GET', url)
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.data);
            })
            .then(data => Promise.resolve(data.data))
            .catch(error => Promise.reject(error));
    },

    getItem: async itemId => {
        return api('GET', `/api/v1/items/${itemId}`)
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.data);
            })
            .then(data => {
                return Promise.resolve(data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            });
    },

    getTags: async (name) => {
        const url = new URL(`${host}/api/v1/tags`);

        url.searchParams.set('per_page', 99);
        const filter = {};
        if (name) filter.name = name;
        url.searchParams.set('filter', JSON.stringify(filter));

        // const options = {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': "application/json"
        //     }
        // }

        return api('GET', url)
        // return fetch(url.toString(), options)
            .then(response => response.json())
            .then(data => Promise.resolve(data.data))
            .catch(error => Promise.reject(error));
    },

    collectItem: async (itemId, action) => {
        const url = `/api/v1/collect-item/${itemId}`;
        return api('PUT', url, action)
            .then(response => response.json())
            .then(data => Promise.resolve(data.data))
            .catch(error => Promise.reject(error));
    },

    createItem: async (tagId, name, data = {}) => {
        const url = `/api/v1/items`;
        const body = JSON.stringify({
            tag: { id: tagId },
            name: name,
            data: JSON.stringify(data),
        });
        return api('POST', url, body)
            .then(response => response.json())
            .then(data => {
                return Promise.resolve(data.data)
            })
            .catch(error => Promise.reject(error))
    },

    createComment: async (comment, itemId, commentId) => {
        const url = `/api/v1/comments`;
        const body = {
            text: comment,
            item_id: itemId,
        };
        if (commentId) {
            body.comment_id = commentId;
        }
        return api('POST', url, JSON.stringify(body))
            .then(response => response.json())
            .then(data => Promise.resolve(data.data))
            .catch(error => Promise.reject(error));
    },

    getComments: async (itemId, page = 0) => {
        const url = new URL(`${host}/api/v1/comments`);

        url.searchParams.set('item_id', itemId);
        url.searchParams.set('page', page);

        return api('GET', url)
            .then(response => response.json())
            .then(data => Promise.resolve(data.data))
            .catch(error => Promise.reject(error));
    },

    likeItem: async (userId, itemId) => {
        const url = new URL(`${host}/api/v1/likes/${itemId}`);
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'user_id': userId,
            },
        };
        return fetch(url.toString(), options)
            .then(response => Promise.resolve(response.json()))
            .catch(error => Promise.reject(error));
    },

    isItemLiked: async (userId, itemId) => {
        const url = new URL(`${host}/api/v1/likes/${itemId}`);
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user_id': userId,
            },
        };
        return fetch(url.toString(), options)
            .then(response => Promise.resolve(response.json()))
            .catch(error => Promise.reject(error));
    },

    signUp: async (name, display_name, email, password) => {
        const url = new URL(`${host}/api/v1/signup`);
        const body = {
            name: name,
            display_name: display_name,
            email: email,
            password: password,
        }
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }
        return fetch(url.toString(), options)
            .then(response => {
                if (!response.ok) {
                    return response.text().then(errorData => {
                        const errorMessage = JSON.parse(errorData);
                        throw new Error(errorMessage.data);
                    })
                }
                return response.json();
            })
            .catch(error => {
                return Promise.reject(error)
            });
    },

    getFriends: async (userId, page = 0, perPage = 100) => {
        const url = new URL(`${host}/api/v1/friends`);
        url.searchParams.set('page', page);
        url.searchParams.set('per_page', perPage);

        return api('GET', url)
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.data);
            })
            .then(data => {
                return Promise.resolve({
                    ...data.data,
                    page: page,
                    perPage: perPage,
                });
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    getPendingFriendRequests: async (userId) => {
        const url = new URL(`${host}/api/v1/friend-requests`);
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user_id': userId,
            },
        };
        return fetch(url.toString(), options)
            .then(response => {
                return response.json();
            })
            .then(data => {
                return Promise.resolve(data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    requestFriend: async (username) => {
        const url = `/api/v1/friends/${username}`;
        return api('POST', url)
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.data);
            })
            .then(data => {
                return Promise.resolve(data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    acceptFriendRequest: async (friendId) => {
        const url = `/api/v1/friends/${friendId}`;
        return api('PUT', url)
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.data);
            })
            .then(data => {
                return Promise.resolve(data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    getUser: async (userId) => {
        const url = new URL(`${host}/api/v1/users/${userId}`);
    },

    getActivities: async (friends, page) => {
        const url = new URL(`${host}/api/v1/activities`);
        url.searchParams.set('page', page);
        url.searchParams.set('per_page', 5);
        if (friends) {
            url.searchParams.set('friends', true);
        }
        return api('GET', url)
            .then(async response => {
                if (response.ok) return response.json()
                    const data = await response.json();
                throw new Error(data.data);
            })
            .then(data => Promise.resolve(data))
            .catch(error => Promise.reject(error));
    },

    createItemUpdate: async (itemId, property, value, type = null, validValues = null) => {
        const url = `/api/v1/item-updates`;
        const body = {
            item_id: itemId,
            property: property,
            value: value,
        }
        if (type) body['type'] = type;
        if (validValues) body['valid_values'] = validValues;
        return api('POST', url, JSON.stringify(body))
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.error);
            })
            .then(data => Promise.resolve(data))
            .catch(error => Promise.reject(error));
    },

    itemUpdateVote: async (itemUpdateId, apply) => {
        const url = new URL(`${host}/api/v1/item-updates/${itemUpdateId}/vote`);
        url.searchParams.set('apply', apply);
        return api('POST', url)
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.error);
            })
            .then(data => Promise.resolve(data))
            .catch(error => Promise.reject(error));
    },

    itemUpdateApply: async (itemUpdateId, apply) => {
        const url = `/api/v1/item-updates/${itemUpdateId}/${apply ? 'apply' : 'reject'}`;
        return api('PUT', url)
            .then(async response => {
                if (response.ok) return response.json();
                const data = await response.json();
                throw new Error(data.error);
            })
            .then(data => Promise.resolve(data))
            .catch(error => Promise.reject(error));
    }
}
