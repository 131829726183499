import { Avatar, Box, Card, CardContent, Link, Typography } from "@mui/material";
import { useRef } from "react";
import { AddActionDialog } from "./AddActionDialog";
import { useSelector } from "react-redux";
import { ItemStats } from "./ItemStats";
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import { TagIcon } from "./TagIcon";

export const ItemList2 = ({ data, tag, items }) => {

    const ref = useRef();
    const user = useSelector(state => state.login.user);

    const handleAddAction = (userId, itemId) => {
        ref.current.openAddAction(userId, itemId);
    }

    // console.log('tag', tag);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {items.map((i, ix) => {
                return (
                    <Card key={ix} sx={{ display: 'flex' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box
                                sx={{
                                    backgroundImage: "url('./placeholder_image.png')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    width: '75px',
                                    minWidth: '75px',
                                }}
                            />
                            <Box sx={{ padding: '10px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', paddingBottom: '10px' }}>
                                    <TagIcon name={i.tag.name} />
                                    <Link underline="hover" href={`/items/${i.id}`}><Typography variant="h5">{i.title}</Typography></Link>
                                </Box>
                                <ItemStats item={i} />
                            </Box>
                        </Box>
                    </Card>
                )
            })}
        </Box>
        // <div>
        //     <AddActionDialog ref={ref} />
        //     {data.items.map(i => {
        //         return (
        //             <Paper key={i.id}>
        //             <div style={{
        //                 padding: "16px",
        //                 borderBottom: "1px solid lightgray",
        //             }}>
        //                 <div style={{
        //                     // paddingBottom: "0px",
        //                 }}>
        //                     <Link underline="hover" href={`/items/${i.id}`}>{i.title}</Link>
        //                     <div><DateText date={parseISO(i.created_at)}/>&nbsp;<Link href={`/user/${i.created_by.name}`} variant="caption">{`@${i.created_by.name}`}</Link><Typography variant="caption">{`${!tag ? ` >${i.tag.name}` : ``}`}</Typography></div>
        //                 </div>
        //                 <div style={{
        //                     display: "flex",
        //                     gap: "4px",
        //                     alignItems: "center",
        //                 }}>
        //                     {user ?
        //                         <IconButton size="small" onClick={() => handleAddAction(user.id, i.id)}><AddCircleOutlineIcon fontSize="small" /></IconButton>
        //                     : null}
        //                     {i.action_stats ? i.action_stats.sort((a, b) => a.action.localeCompare(b.action)).map(a => {
        //                         const key = `action-stat-id-${a.action}`;
        //                         const label = <><span>{a.action}</span>&nbsp;<CountText num={a.count} /></>
        //                         const color = i.actions && i.actions.includes(a.action) ? 'primary' : 'default';
        //                         return <Chip key={key} label={label} size="small" color={color} icon={<TaskAltIcon />} />
        //                     }) : null}
        //                     <LikeChip count={i.like_count} />
        //                     <CommentChip count={i.comment_count} />
        //                 </div>
        //             </div>
        //             </Paper>
        //         );
        //     })}
        // </div>
    );
}