import { Box, Card, CardContent } from "@mui/material";
import { Item } from "./Item";

export const ItemList3 = ({ items, totalCount }) => {

    // console.log('items', items);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {items.map((i, index) => {
                return (
                    <Card key={index}>
                        <CardContent>
                            <Item item={i} />
                        </CardContent>
                    </Card>
                );
            })}
        </Box>
    );
}