import { IconButton } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import CloseIcon from '@mui/icons-material/Close';

export const snakeToCamel = str => {
    return str.replace(
      /(?!^)_(.)/g,
      (_, char) => char.toUpperCase()
    );
  }

export const snakeToTitleCase = str => {
    return str
        .split("_")
        .filter(x => x.length > 0)
        .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
        .join(" ");
}

export const notification = (message, type) => {
  console.log('notification', message, type);
  enqueueSnackbar(message, {
    variant: type,
    // persist: true,
    action: (key) => (
        <IconButton
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(key)}
        >
            <CloseIcon />
        </IconButton>
    )
});
}

export const shortenNumber = n => {
  // console.log('n', n);
  // console.log('typeof n', typeof n);
  if (typeof n !== 'number')
    return 0;
  if (n < 1000) return n;
  else if (n < 1_000_000) return `${(n / 1000).toFixed(1)}k+`;
  else if (n < 1_000_000_000) return `${(n / 1_000_000).toFixed(1)}m+`;
}