import { Chip, Tooltip } from "@mui/material";
import { CountText } from "./CountText";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

export const LikeChip = ({ count }) => {

    return (
        <Tooltip title="Likes" placement="top" arrow>
            <Chip icon={<ThumbUpIcon fontSize="small" />} label={<CountText num={count || 0} />} size="small" />
        </Tooltip>
    );
}