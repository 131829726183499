import { Box, Link, Typography } from "@mui/material";
import { TagIcon } from "./TagIcon";

export const ItemTitle = ({ item }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', paddingBottom: '10px' }}>
            <TagIcon name={item.tag.name} />
            <Link href={`/items/${item.id}`} underline='hover'><Typography variant='h5'>{item.title}</Typography></Link>
        </Box>
    );
}