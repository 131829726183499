import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import {Provider} from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { BorderColor } from '@mui/icons-material';
import { SnackbarProvider } from 'notistack';

const theme = createTheme({
  palette: {
    background: {
      paper: '#f0f0f0', // Set your desired global background color for Paper
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#918868',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'green', // Change this to your desired color
          },
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff'
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <Provider store={store}>
        {/* <Auth0Provider
          domain="tramos.us.auth0.com"
          clientId="skktCeDLwU0lplLCEFH0q6mcnKBCfv7w"
          authorizationParams={{
            redirect_uri: window.location.origin
          }}
        > */}
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider>
                  <App />
                </SnackbarProvider>
              </LocalizationProvider>
            </ThemeProvider>
        {/* </Auth0Provider> */}
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
