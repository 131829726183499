import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        notifications: [],
    },
    reducers: {
        addNotification: (state, action) => {
            const notification = {
                ...action.payload,
                id: new Date().getTime(),
                open: true,
            }
            state.notifications.push(notification);
        },
        closeNotification: (state, action) => {
            const ix = state.notifications.findIndex(n => n.id === action.payload);
            if (ix !== -1) {
                state.notifications[ix].open = false;
            }
        },
        removeNotification: (state, action) => {
            state.notifications = state.notifications.filter(n => n.id !== action.payload);
        }
    },
});

export const { addNotification, closeNotification, removeNotification } = notificationSlice.actions;
export default notificationSlice.reducer;