import MenuBookIcon from '@mui/icons-material/MenuBook';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import TvIcon from '@mui/icons-material/Tv';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import PeopleIcon from '@mui/icons-material/People';
import MapIcon from '@mui/icons-material/Map';
import PsychologyIcon from '@mui/icons-material/Psychology';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import { Avatar, Tooltip } from '@mui/material';

const TagIcons = {
    'books': <MenuBookIcon />,
    'events': <NewspaperIcon />,
    'movies': <LocalMoviesIcon />,
    'people': <PeopleIcon />,
    'places': <MapIcon />,
    'pictures': <PhotoCameraIcon />,
    'shows': <TvIcon />,
    'thoughts': <PsychologyIcon />,
    'video games': <VideogameAssetIcon />,
};

export const GetTagIcon = name => {
    if (name in TagIcons) {
        return TagIcons[name];
    }
    return <PsychologyAltIcon />
}

export const TagIcon = ({ name }) => {
    return (
        <Tooltip title={name}>
            <Avatar sx={{ width: '32px', height: '32px' }}>
                {GetTagIcon(name)}
            </Avatar>
        </Tooltip>
    );
}