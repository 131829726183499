// import logo from './logo.svg';
import './App.css';
// import { useState } from 'react';
// import ItemList from './components/ItemList';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import { Dashboard } from './components/Dashboard';
import { Error } from './components/Error';
import {Login} from './components/Login';
import { useDispatch } from 'react-redux';
import { setUser } from './slices/loginSlice';
import { ItemDetails } from './components/ItemDetails';
import { ItemCreate } from './components/ItemCreate';
import { SignUp } from './components/SignUp';
import { UserItems } from './components/UserItems';
import { UserProfile } from './components/UserProfile';
import { Activities } from './components/Activities';
import { Browse } from './components/Browse';
import { Popular } from './components/Popular';

const App = () => {

  const dispatch = useDispatch();
  const user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
  if (user) {
    dispatch(setUser(user))
  }

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/popular" element={<Popular />} />
        <Route path="/browse" element={<Browse />} />
        <Route path="/login" element={<Login />} />
        <Route path="/items/:itemId" element={<ItemDetails />} />
        <Route path="/create-item" element={<ItemCreate />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/user/:userId/:section" element={<UserProfile />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Layout>
  );
}

export default App;
