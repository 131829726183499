import { Box, Button, IconButton, Paper, TextField, Typography } from "@mui/material";
import { TagChooser } from "./TagChooser";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ItemList2 } from "./ItemList2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MyListApi } from "../utils/restApi";
import { ItemFilterSelect } from "./ItemFilterSelect";
import SearchIcon from '@mui/icons-material/Search';
import { fetchItems, resetStatus, setPage } from "../slices/itemsSlice2";

const PER_PAGE = 10;

export const Browse = ({ userId }) => {

    const user = useSelector(state => state.login.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const [searchParams] = useSearchParams();
    // const tt = searchParams.get('tag') || '';

    const [tag, setTag] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(['createdAt', 'DESC']);

    const {items, status, error, count } = useSelector(state => state.items2);

    const getSort = sort => {
        switch (sort) {
            case 'new': return ['createdAt', 'DESC'];
            case 'popular': return null;
            case 'alpha': return ['name', 'ASC'];
            default: return null;
        }
    }

    useEffect(() => {
        // console.log('browse');
        dispatch(resetStatus());
        const searchTag = localStorage.getItem('searchTag') || '';
        const searchText = localStorage.getItem('searchText') || '';
        const searchPage = parseInt(localStorage.getItem('searchPage') || '0');

        // console.log('searchTag', searchTag);
        // console.log('searchText', searchText);
        // console.log('searchPage', searchPage);

        setTag(searchTag);
        setSearch(searchText);
        setPage(searchPage);
    }, []);

    useEffect(() => {
        localStorage.setItem('searchTag', tag);
        localStorage.setItem('searchText', search);
        localStorage.setItem('searchPage', page);
        // console.log('store tag', tag);
        // console.log('store search', search);
        // console.log('store page', page);
    }, [tag, page, search]);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchItems({ userId: null, tag: tag, name: search, sort: getSort(sort), isPopular: false, page: page, perPage: PER_PAGE }));
        }
    }, [status, dispatch])

    // useEffect(() => {
    //     if (status === 'idle') {
    //         dispatch(fetchItems({ userId: null, tag: tag, name: search, sort: getSort(sort), isPopular: false, page: page, perPage: PER_PAGE }));
    //     }
    // }, [status, page, tag, dispatch]);

    const handleSelect = tag => {
        setTag(tag?.name || '');
    }

    const handleSearchText = e => {
        setSearch(e.target.value);
    }

    const handleSort = sortBy => {
        setSort(sortBy);
    }

    const handleNext = e => {
        setPage(page + 1);
        dispatch(resetStatus());
        dispatch(fetchItems({ userId: null, tag: tag, name: search, sort: getSort(sort), isPopular: false, page: page + 1, perPage: PER_PAGE }));
    }

    const handlePrev = e => {
        setPage(page - 1);
        dispatch(resetStatus());
        dispatch(fetchItems({ userId: null, tag: tag, name: search, sort: getSort(sort), isPopular: false, page: page - 1, perPage: PER_PAGE }));
        // dispatch(setPage(page - 1));
    }

    const handleSearch = () => {
        setPage(0);
        dispatch(resetStatus());
        dispatch(fetchItems({ userId: null, tag: tag, name: search, sort: getSort(sort), isPopular: false, page: page, perPage: PER_PAGE }));
    }

    // if (status !== 'succeeded') {
    //     return <Box></Box>;
    // }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box sx={{ borderBottom: '1px solid #cccccc' }}>
                <Typography variant="h4" sx={{color: 'black'}}>Search</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '10px', padding: '10px' }}>
                <TagChooser curTag={tag} onSelect={handleSelect} sx={{ maxWidth: '150px', minWidth: '150px' }} />
                {/* <ItemFilterSelect selectSort={handleSort} sx={{ flexGrow: 1 }} /> */}
                <TextField variant="filled" size="small" placeholder="search" sx={{ flexGrow: 2 }} value={search} onChange={handleSearchText} />
                <Button variant="contained" endIcon={<SearchIcon />} onClick={handleSearch}>Search</Button>
            </Box>
            <Box sx={{ paddingTop: '10px' }}>
                <ItemList2 tag={tag} items={items || []} />
            </Box>
            <Box sx={{ paddingTop: '10px', marginTop: '10px', borderTop: '1px solid #cccccc' }}>
                <Button onClick={handlePrev} disabled={page <= 0} size="small">Prev</Button>
                <Button onClick={handleNext} disabled={(page + 1) * PER_PAGE >= count} size="small">Next</Button>
            </Box>
        </Box>

        // <Paper>
        //     <div>
        //         <h1>Browse</h1>
        //     </div>
        //     <div style={{
        //         padding: "16px",
        //     }}>
        //         <div style={{
        //             paddingBottom: "16px",
        //             display: "flex",
        //             gap: "8px",
        //             borderBottom: "1px solid lightgray",
        //         }}>
        //             <div style={{
        //                 display: "flex",
        //                 alignItems: "center",
        //                 justifyContent: "flex-start",
        //                 gap: "8px",
        //                 flex: "1",
        //             }}>
        //                 <TagChooser onSelect={handleSelect} />
        //             </div>
        //             <div style={{
        //                 display: "flex",
        //                 alignItems: "center",
        //                 justifyContent: "flex-end",
        //                 flex: "3",
        //                 gap: "8px",
        //             }}>
        //                 <ItemFilterSelect selectSort={handleSort} />
        //                 <TextField variant="filled" size="small" placeholder="search" onChange={handleSearch} />
        //                 <Button><SearchIcon /></Button>
        //             </div>
        //         </div>
        //         <div>
        //             <ItemList2 data={browse} tag={tag} />
        //             <Button onClick={handlePrev} disabled={page <= 0} size="small">Prev</Button>
        //             <Button onClick={handleNext} disabled={(page + 1) * PER_PAGE >= browse.count} size="small">Next</Button>
        //         </div>
        //     </div>
        // </Paper>
    );
}