import { Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPopularItems, resetStatus } from "../slices/itemsSlice2";
import { ItemList3 } from "./ItemList3";

export const Popular = props => {

    const dispatch = useDispatch();

    const items = useSelector((state) => state.items2.items);
    const itemsStatus = useSelector((state) => state.items2.status);
    const itemsError = useSelector((state) => state.items2.error);

    useEffect(() => {
        dispatch(resetStatus());
    }, []);

    useEffect(() => {
        if (itemsStatus === 'idle') {
            dispatch(fetchPopularItems({ tag: '', page: 0 }));
        }
    }, [itemsStatus, dispatch]);

    if (itemsStatus != 'succeeded') {
        return (
            <CircularProgress />
        );
    }

    const handleRefresh = () => {
        dispatch(resetStatus());
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%', minHeight: '400px' }}>
            <Box sx={{ borderBottom: '1px solid #cccccc' }}>
                <Typography variant="h4">Popular</Typography>
            </Box>
            <Box>
                <Button onClick={handleRefresh}>Refresh</Button>
            </Box>
            <ItemList3 items={items} />
        </Box>
    );
}